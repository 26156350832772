@import '../../styles/breakpoints';
@import '../../styles/dimensions';

$list-icon-size: 72px;

.container {
    padding-bottom: space(16);
    padding-top: space(16);
    position: relative;

    @include screen-min-width('md') {
        &.tiny-spacing {
            padding-bottom: 0;
            padding-top: space(6);
        }

        &.compact-spacing {
            padding-bottom: space(25);
            padding-top: space(25);
        }

        &.default-spacing {
            padding-bottom: space(32);
            padding-top: space(32);
        }
    }

    @include screen-max-width('md') {
        &.tiny-spacing {
            padding-bottom: 0;
        }
    }

    // targets elements within Markdown component
    h2 {
        margin: 0;
    }
}

.row {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: $gap-larger;

    @include screen-min-width('md') {
        flex-direction: row;
        gap: 0;
    }
}

.column {
    display: flex;
    order: 1;
    width: 100%;

    @include screen-min-width('md') {
        flex: 1 1 50%;
        width: auto;
    }
}

.column-order-mobile-top {
    @include screen-max-width('md') {
        order: 0;
    }
}

.column-order-mobile-bottom {
    @include screen-max-width('md') {
        order: 2;
    }
}

.column-order-0 {
    @include screen-min-width('md') {
        order: 0;
    }
}

.column-order-2 {
    @include screen-min-width('md') {
        order: 2;
    }
}

.column-left {
    justify-content: right;

    .column-inner {
        @include screen-min-width('md') {
            margin-right: space(16);
        }
    }

    .column-inner--small {
        @include screen-min-width('md') {
            margin-right: space(11);
        }
    }
}

.column-right {
    justify-content: left;

    .column-inner {
        @include screen-min-width('md') {
            margin-left: space(16);
        }
    }

    .column-inner--small {
        @include screen-min-width('md') {
            margin-left: space(32);
        }
    }
}

.column-inner {
    width: 100%;

    @include screen-min-width('md') {
        max-width: $copy-max-width-large;
    }
}

.column-inner--small {
    @include screen-min-width('md') {
        max-width: $copy-max-width-medium;
    }
}

.copy-column {
    display: flex;
}

.copy-container {
    @include screen-min-width('md') {
        margin-bottom: $gap-medium;
        margin-top: $gap-medium;
    }
}

.supporting-copy {
    padding-bottom: $gap-medium;
    padding-top: $gap-medium;
}

.media {
    display: block;
    margin-bottom: $gap-large;
    width: 100%;

    @include screen-min-width('md') {
        margin-bottom: 0;
    }
}

.token-icon {
    left: 12%;
    position: absolute;

    top: 5%;

    @include screen-min-width('md') {
        left: 10%;
        top: 20%;
    }
}

.icon-list-container {
    padding-bottom: $gap-medium;
}

.icon-list-item--heading {
    img {
        flex: 0 0 $list-icon-size;
    }
}

.icon-list-item--canary {
    border: 10px solid var(--step-coal-mine-canary);
    color: var(--step-coal-mine-canary);
}

.icon-list-item--fuscia {
    color: var(--step-future-fuscia);
}

.icon-list-item--cyanora {
    color: var(--step-cyanora);
}

.container form {
    align-items: flex-start;
}

.buttons {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: $gap-small-medium;
    justify-content: flex-start;
    margin: $gap-large 0;

    @include screen-min-width('md') {
        flex-direction: row;

        > div,
        a {
            flex: 0 0 50%;
        }
    }
}
