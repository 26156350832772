@import '../../styles/utils';
@import '../../styles/dimensions';
@import '../../styles/colors';

.wrapper {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;

    :global video,
    :global .react-player__preview {
        bottom: 0;
        flex: 1;
        left: 0;
        min-height: 100%;
        outline: none;
        position: absolute;
        right: 0;
        top: 0;
    }
}

.poster {
    height: auto;
    visibility: hidden;
    width: 100%;

    img {
        max-width: 100%;
    }
}

.hidden {
    visibility: hidden;
}

.player {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.play:active circle {
    fill: var(--step-midnight);
}

.play:hover,
.play:focus {
    @include hoverme;
}
