/**
 * Note: do not reorder the breakpoints map!
 * Breakpoint-specific classes are generated using this map, and classes for
 * smaller breakpoint should come first and use the (min-width: ) rule.
 */
$breakpoints: (
    xxs: 320px,
    xs: 480px,
    sm: 667px,
    md: 768px,
    tablet: 900px,
    lg: 1024px,
    xl: 1200px,
);

@function breakpoint($type) {
    @return map-get($map: $breakpoints, $key: $type);
}

@mixin screen-width($min, $max) {
    @media screen and (min-width: (breakpoint($min) + 1px)) and (max-width: breakpoint($max)) {
        @content;
    }
}

@mixin screen-min-width($type) {
    @media screen and (min-width: (breakpoint($type) + 1px)) {
        @content;
    }
}

@mixin screen-max-width($type) {
    @media screen and (max-width: breakpoint($type)) {
        @content;
    }
}

@mixin screen-between($min, $max) {
    @media screen and (min-width: (breakpoint($min) + 1px)) and (max-width: breakpoint($max)) {
        @content;
    }
}

@mixin breakpoint-class($classname, $type) {
    .#{$classname}--#{$type} {
        @include screen-min-width($type) {
            @content;
        }
    }
}

@mixin responsive-class($classname) {
    @each $breakpoint-key, $breakpoint in $breakpoints {
        @include breakpoint-class($classname, $breakpoint-key) {
            @content;
        }
    }
}
